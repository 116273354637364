.img-wrap {
  width: 200px;
  height: 150px;
  position: relative;
  display: inline-block;
  overflow: hidden;
  margin: 0;
}

footer.additional-footer {
  padding: 0 !important;
  padding-bottom: 10px !important;
}
