.wrapper.login {
  align-items: center;
}

.login-cont {
  width: 100%;
  max-width: 450px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  padding: 50px;
  text-align: center;
}
.login-cont h2 {
  margin: 40px 0 25px;
}
