@media (min-width: 1440px) {
  .height-bi {
    height: 1000px;
  }
}
/* 
  ##Device = Desktops
  ##Screen = 1281px to higher resolution desktops
*/

@media (min-width: 1281px) and (max-width: 1439px) {
  .height-bi {
    height: 700px;
  }
}

/* 
    ##Device = Laptops, Desktops
    ##Screen = B/w 1025px to 1280px
  */

@media (min-width: 1025px) and (max-width: 1280px) {
  .height-bi {
    height: 700px;
  }
}

/* 
    ##Device = Tablets, Ipads (portrait)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) {
  .height-bi {
    height: 700px;
  }
}

/* 
    ##Device = Tablets, Ipads (landscape)
    ##Screen = B/w 768px to 1024px
  */

@media (min-width: 768px) and (max-width: 1024px) and (orientation: landscape) {
  .height-bi {
    height: 400px;
  }
}

/* 
    ##Device = Low Resolution Tablets, Mobiles (Landscape)
    ##Screen = B/w 481px to 767px
  */

@media (min-width: 481px) and (max-width: 767px) {
  .height-bi {
    height: 340px;
  }
}

/* 
    ##Device = Most of the Smartphones Mobiles (Portrait)
    ##Screen = B/w 320px to 479px
  */

@media (min-width: 320px) and (max-width: 480px) {
  .height-bi {
    height: 160px;
  }
}
