/* new design */
@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700;800&display=swap");

body,
html {
  margin: 0;
  padding: 0;
  width: 100%;
  color: #232323;
  font-size: 15px;
  height: 100%;
  min-height: 100%;
  background-color: #eee;
  font-weight: 400;
  display: flex;
}
* {
  font-family: "Manrope", sans-serif;
  box-sizing: border-box;
  line-height: 1;
}
a {
  color: #888;
  text-decoration: none;
}
a:hover {
  color: #000;
}

h2 {
  font-size: 22px;
}

.app {
  min-height: 100%;
  display: flex;
}
.wrapper {
  width: 100%;
  min-height: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
}
.wrapper.login {
  align-items: center;
}

.login-cont {
  width: 100%;
  max-width: 450px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
  padding: 50px;
  text-align: center;
}
.login-cont h2 {
  margin: 40px 0 25px;
}

header {
  position: sticky;
  top: 20px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  border-radius: 10px;
  background-color: #eee;
  margin-bottom: 15px;
  z-index: 2;
}
header.space {
  justify-content: space-between;
}

.breadcrumb {
  font-size: 18px;
  display: flex;
  gap: 10px;
  align-items: center;
}
.breadcrumb a {
  font-weight: 400;
}
.breadcrumb svg {
  width: 12px;
}
.breadcrumb svg path {
  fill: #232323;
}

/* nombre de usuario */
.user-name {
  display: flex;
  gap: 5px;
  align-items: center;
  font-size: 14px;
  position: relative;
  cursor: pointer;
  transition: 0.3s;
  padding: 5px 10px;
  border-radius: 10px;
}
.user-name:hover {
  background-color: #ccc;
}
.user-name .icon {
  width: 18px;
}
.user-name .arrow {
  width: 8px;
}
.user-name path {
  fill: #232323;
}

.user-name .submenu {
  width: 220px;
  padding: 15px 10px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  position: absolute;
  right: 0;
  top: 110%;
  margin-top: -10px;
  transition: 0.1s;
  visibility: hidden;
  opacity: 0;
}
.user-name .submenu a {
  position: relative;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 14px;
  color: #707070;
  transition: 0.3s;
}
.user-name .submenu a:hover {
  background-color: #eee;
}
.user-name .submenu a svg {
  width: 15px;
}
.user-name .submenu a svg path {
  fill: #707070;
}
.user-name:hover .submenu {
  visibility: visible;
  opacity: 1;
  margin-top: 0;
}

/* menú */
.bar-menu {
  width: 80px;
  flex-grow: 0;
  flex-shrink: 0;
  border-radius: 10px;
  background-color: #000;
  padding: 25px 15px;
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 5px;
  transition: 0.3s;
  text-align: center;
  align-items: center;
  margin-right: 30px;
}
.bar-menu .logo-cont {
  height: 60px;
}
.bar-menu .logo {
  width: 50px;
  transition: 0.3s;
}
.bar-menu a {
  padding: 12px 15px;
  display: flex;
  align-items: center;
  color: #fff;
  gap: 0;
  text-align: center;
  transition: 0.3s;
  width: 100%;
  justify-content: center;
  border-radius: 10px;
}
.bar-menu a span {
  overflow: hidden;
  width: 0;
}
.bar-menu a svg {
  width: 18px;
  flex-shrink: 0;
}
.bar-menu a svg path {
  fill: #fff;
}
.bar-menu a:hover,
.bar-menu a.active {
  background-color: #232323;
}

.bar-menu .expand {
  position: absolute;
  right: -15px;
  top: 50px;
  width: 30px;
  height: 30px;
  background-color: #fff;
  cursor: pointer;
  border-radius: 50%;
  transition: 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  z-index: 2;
  border: none;
  outline: none;
  opacity: 0;
  visibility: hidden;
}
.bar-menu .expand:hover {
  transform: scale(1.1);
}
.bar-menu .expand svg {
  width: 12px;
}
.bar-menu .expand svg path {
  fill: #000;
}
.bar-menu:hover .expand {
  opacity: 1;
  visibility: visible;
}

.bar-menu.open {
  width: 200px;
}
.bar-menu.open .logo {
  width: 100px;
}
.bar-menu.open a {
  gap: 10px;
  justify-content: flex-start;
}
.bar-menu.open span {
  width: auto;
}
.bar-menu.open .expand {
  transform: rotate(180deg);
}

/* contenido */
.content {
  position: relative;
  flex-grow: 1;
  flex-shrink: 1;
  transition: 0.3s;
}

/* tarjetas */
.cards-cont {
  position: relative;
  padding: 20px 0;
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
}
.cards-cont .card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  padding: 20px;
  width: 200px;
  text-align: center;
  transition: 0.3s;
  cursor: pointer;
}
.cards-cont .card:hover {
  box-shadow: 2px 2px 7px rgba(0, 0, 0, 0.4);
}
.cards-cont .card .image {
  margin-bottom: 10px;
  width: 80px;
}
.cards-cont .card .name {
  font-size: 18px;
  font-weight: 600;
  padding: 10px 0;
  color: #232323;
}
.cards-cont .card .tag {
  color: #929292;
}

.cards-cont .card.tablero {
  text-align: left;
}
.cards-cont .card.tablero .inline {
  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: flex-start;
}
.cards-cont .card.tablero .image {
  width: 30px;
}

.cards-cont.tiny .card.tablero {
  padding: 10px;
}
.cards-cont.tiny .card.tablero .name {
  padding: 0 0 15px;
}

/* iframe */
.iframe-cont {
  padding: 10px;
  border-radius: 10px;
  background-color: #fff;
  width: 100%;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
.iframe-cont iframe {
  border: none;
  width: 100%;
  height: 800px;
  border-radius: 10px;
}

/* tablas */
.table-head {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0;
}
.table-head .col-filters {
  display: flex;
  gap: 20px;
}
.table-head .search-cont {
  position: relative;
}
.table-head .search-cont input,
.table-head .search-cont select {
  padding: 10px 25px 10px 10px;
  width: 300px;
  border: none;
  border-radius: 10px;
  transition: 0.3s;
  height: 100%;
}
.table-head .search-cont input:focus,
.table-head .search-cont select:focus {
  border: none;
  outline: none;
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}
.table-head .search-cont svg {
  position: absolute;
  top: 10px;
  right: 5px;
  width: 18px;
}
.table-head .search-cont svg path {
  fill: #707070;
}

.checks-cont {
  position: relative;
  display: flex;
  gap: 10px;
}
.checks-cont input[type="radio"] {
  display: none;
}
.checks-cont label {
  display: flex;
  cursor: pointer;
}
.checks-cont label div {
  width: 80px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
  border: 1px solid #ccc;
  color: #707070;
  border-radius: 10px;
}
.checks-cont label:hover div {
  border-color: #232323;
  color: #232323;
}
.checks-cont label > input:checked + div {
  background-color: #232323;
  border-color: #232323;
  color: #fff;
}

.table-cont {
  position: relative;
  background-color: #fff;
  overflow: hidden;
  border-radius: 10px;
  overflow-x: auto;
  margin-top: 20px;
}
.table-cont table {
  position: relative;
  width: 100%;
}
.table-cont table th {
  font-weight: 700;
  padding: 20px;
  border-bottom: 2px solid #ccc;
  text-align: left;
}
.table-cont table td {
  padding: 20px;
  border-bottom: 1px solid #ccc;
}
.table-cont table tr:last-child td {
  border-bottom: none;
}

.actions-cont {
  position: relative;
  display: flex;
  align-items: center;
  gap: 10px;
}
.actions-cont button {
  border: none;
  outline: none;
  cursor: pointer;
  position: relative;
  background: no-repeat;
}
.actions-cont svg {
  width: 25px;
}
.actions-cont svg path {
  fill: #707070;
}

.actions-cont button .tooltip {
  position: absolute;
  bottom: 110%;
  left: 50%;
  width: 80px;
  background-color: #000;
  border-radius: 5px;
  color: #fff;
  padding: 10px 5px;
  text-align: center;
  margin-left: -40px;
  transition: 0.2s;
  margin-bottom: -5px;
  opacity: 0;
  visibility: hidden;
  font-size: 12px;
}
.actions-cont button .tooltip::after {
  content: "";
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: #000 transparent transparent transparent;
}

.actions-cont button:hover .tooltip {
  visibility: visible;
  opacity: 1;
  margin-bottom: 0;
}

.actions-cont.right {
  justify-content: flex-end;
}
.actions-cont.tiny {
  gap: 5px;
}
.actions-cont.tiny svg {
  width: 18px;
}

/* editar empresa */
.logo-company {
  text-align: center;
  padding: 20px;
}

.add-logo label {
  cursor: pointer;
}
.add-logo label div {
  padding: 10px 0;
}
.add-logo input[type="file"] {
  display: none;
}
.add-logo svg {
  width: 70px;
}
.add-logo path {
  fill: #707070;
}

/* popups */
.popup-cont {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  overflow-y: auto;
  z-index: 5;
  animation: fadeIn 0.5s;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.popup-cont .modal {
  padding: 30px 20px 20px;
  width: 100%;
  max-width: 400px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  margin: 5% auto 0;
}
.popup-cont .modal h3 {
  margin: 0 0 20px;
  padding: 0 10px;
}

.popup-cont.big .modal {
  max-width: 600px;
}
.popup-cont.medium .modal {
  max-width: 500px;
}

.popup-cont.alert .modal {
  text-align: center;
}
.popup-cont.alert .modal svg {
  margin-bottom: 20px;
  width: 130px;
}
.popup-cont.alert .modal svg path {
  fill: #707070;
}

.popup-cont.up {
  z-index: 6;
}

/* estilos formulario */
.cont-form {
  padding: 15px 10px;
  position: relative;
  flex-grow: 1;
}
.cont-form label {
  position: absolute;
  left: 25px;
  top: 28px;
  pointer-events: none;
  transition: 0.3s;
}
.cont-form label span {
  color: #f00;
  font-size: 15px;
  margin-left: 3px;
  font-weight: 500;
}

.form-control {
  border: 1px solid #eee;
  transition: 0.3s;
  padding: 10px 15px;
  height: 40px;
  width: 100%;
  background-color: #eee;
  font-size: 15px;
  border-radius: 8px;
  appearance: none;
}
.form-control:focus {
  outline: none;
  box-shadow: none;
  border-color: #000;
}

.cont-form.icon svg {
  position: absolute;
  top: 25px;
  right: 20px;
  width: 20px;
}
.cont-form.icon svg path {
  fill: #707070;
}
.cont-form.icon .form-control {
  padding-right: 35px;
}

.cont-form.focus label {
  top: 0;
  left: 18px;
  color: #000;
  font-size: 12px;
}

.cont-form.req {
  color: #f00;
}
.cont-form.req .form-control {
  border-color: #f00;
}

.form-line {
  display: flex;
}
.form-line .cont-form {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
}

.req-alert {
  color: #f00;
  display: none;
  padding-top: 5px;
  font-size: 12px;
}

/* botones */
.buttons-cont {
  display: flex;
  padding: 15px 10px;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  flex-wrap: nowrap;
}
.buttons-cont.wrap {
  flex-wrap: wrap;
}
.buttons-cont.center {
  justify-content: center;
}

.btn {
  padding: 12px 25px;
  cursor: pointer;
  transition: 0.3s;
  text-decoration: none;
  text-align: center;
  position: relative;
  border: none;
  border-radius: 8px;
  font-size: 16px;
  background-color: #232323;
  color: #fff;
  transition: 0.3s;
  display: inline-block;
}
.btn:hover {
  background-color: #000;
}
.btn:focus {
  outline: none;
  box-shadow: none;
}

.btn.secondary {
  background-color: #747474;
}
.btn.secondary:hover {
  text-decoration: none;
  background-color: #515151;
}

.btn.icon {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: center;
}
.btn.icon svg {
  width: 20px;
}
.btn.icon svg path {
  fill: #fff;
}

.btn.outline {
  border: 1px solid #eee;
  color: #707070;
  background-color: #fff;
}
.btn.outline.icon path {
  fill: #707070;
}

.btn.full {
  width: 100%;
}

/* comun styles */
.text-center {
  text-align: center;
}
.margin-bottom-20 {
  margin-bottom: 20px;
}

footer {
  margin-top: auto;
  bottom: 20px;
  text-align: center;
  width: max-content;
}
