.rounded-input-error {
  margin: 5px 0px 0px 11px;
  color: red;
  font-size: 0.8rem;
}

.form-input-error {
  margin: 5px 0px 0px 11px;
  color: red;
  font-size: 0.8rem;
}

select {
  cursor: pointer;
}

option {
  cursor: pointer;
}
